/* eslint no-console:0 */
// NOTE: The goal is to get rid of these.
require('vendor_js/jquery')
require('vendor_js/common/bootstrap')

require('fullcalendar')
require('fullcalendar/dist/locale/de')
require('fullcalendar/dist/locale/es')
require('fullcalendar/dist/locale/fr')
require('select2')

require('vendor_js/jquery/jquery-sortable-min')
require('vendor_js/jquery/jquery.auto-complete')
require('vendor_js/jquery/jquery.tablesort.min')
require('vendor_js/jquery/jquery.timezone-picker')
require('vendor_js/jquery/jquery.sparkline')

require('jquery-ujs')
